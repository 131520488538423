import React from 'react'
import classes from './App.module.scss'
import logo from './logo.svg'

const App = () => (
	<div className={classes.App}>
		<header>
			<h1>Daybreak Treatment Care</h1>
			<p>
				A Day Treatment Program for Adults with Psychiatric Illness
			</p>

			<hr />
		</header>

		<main>

			<p className={classes.comingSoon}>
				Full site coming soon!
			</p>

			<div className={classes.addressRow}>
				<div className={classes.addressBlock}>
					<h4>Daybreak I</h4>
					<address>
						368 White Horse Pike<br />
						Atco, NJ 08004<br />
						Phone: <a href="tel:8567535788">(856) 753-5788</a><br />
						Fax: (856) 753-3622
					</address>
				</div>

				<div className={classes.addressBlock}>
					<h4>Daybreak II (Main Office)</h4>
					<address>
						262 Pinedge Drive<br />
						West Berlin, NJ 08091<br />
						Phone: <a href="tel:8567538111">(856) 753-8111</a><br />
						Fax: (856) 753-3339
					</address>
				</div>
			</div>
		</main>

		<footer>
			<img src={logo} alt='Main logo'/>
		</footer>
	</div>
)

export default App
